import React from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import PayoutTable from './PayoutTable'
import InitiatePayout from './InitiatePayout'
import { useSelector } from 'react-redux'
import ChangeStatusType from './ChangeStatusType'
import AmountChange from './AmountChange'
import PayoutTableNew from './PayoutTableNew'

export default function PayoutManagementNew() {
  const {
    payoutManagement: { updatedInfo, changeInfo, showPayoutModal: { show, type } }
  } = useSelector((state) => state.admin)

  return (
    <AdminWrapper>
       <div className="payable-tblouter order-tbl tbl-bg-white">
        {(show && type == 'manage') &&
          <InitiatePayout />}

        {(show && type == 'changstatustype') &&
          <ChangeStatusType statusInfo={updatedInfo} />}

        {(show && type == 'change_amount') &&
          <AmountChange changeInfo={changeInfo} />}


        <PayoutTableNew />
      </div>
    </AdminWrapper>
  )
}
