import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetProfileListings, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setDetails, setOrderValues, showOrderModalFunc, setSalesValues, showSalesModalFunc } from '../../../../config/redux/slice/customerSlice'
import { showPayoutModalFunc } from '../../../../config/redux/slice/adminSlice'

import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import { Link, useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import SellerDeatils from '../listing_management/SellerDetails';
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
// import ConfirmDelivery from './ConfirmDelivery'
import moment from 'moment'
import Pagination from "react-js-pagination";
// import RefundForm from './RefundForm';
import { format } from 'date-fns';
import AlertPopup from '../../../components/alertpopup'
export default function PayoutTableNew() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token } = useSelector((state) => state.authData)
    const [payoutsByEventData, setpayoutsByEventData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [refun, setRefun] = useState('');
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [showDbPopup, setShowDbPopup] = useState(false)
    const [showComplaintPopup, setShowComplaintPopup] = useState(false)
    const [showViewComplaintPopup, setShowViewComplaintPopup] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedViewItem, setSelectedViewItem] = useState(null);
    const [showDbPopup1, setShowDbPopup1] = useState(false)
    const [selectedItem1, setSelectedItem1] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        getPayoutsByEvent()
    }, [])

    const {
        payoutManagement: { newData, payoutData, payoutEventId, updatedInfo, changeInfo }
    } = useSelector((state) => state.admin)

    const { userData: { user_role } } = useSelector((state) => state.authData);

    const isSuperAdmin = user_role === 'SUPER_ADMIN';

    const getPayoutsByEvent = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_payouts_by_event_latest(payoutEventId)}?page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        console.log("🚀 ~ getPayoutsByEvent ~ res:", res)
        if (res !== undefined && res.status < 400) {
            let payoutEventList = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            console.log("🚀 ~ getPayoutsByEvent ~ res:...................", payoutEventList)
            console.log("🚀 ~ getPayoutsByEvent ~ res:...................", totalCount)
            setpayoutsByEventData(payoutEventList);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
    }

    // const toggleRow = (index) => {
    //     setExpandedRows(prevExpandedRows => ({
    //         ...prevExpandedRows,
    //         [index]: !prevExpandedRows[index]
    //     }));
    //     setNestedExpandedRows({});
    // };


    // const toggleNestedRow = (mainIndex, nestedIndex) => {
    //     setNestedExpandedRows(prevState => ({
    //         ...prevState,
    //         [mainIndex]: {
    //             ...prevState[mainIndex],
    //             [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
    //         }
    //     }));
    // };

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };




    const confirmClick = (index, data) => {
        console.log("🚀 ~ confirmClick ~ data:", data)
        setItem(data, 'confirm')
    }


    const setItem = (data, type) => {
        console.log(data)
        dispatch(setDetails({ details: data, type: 'sales-items' }))
        if (type == 'confirm') {
            dispatch(showSalesModalFunc({ modal: true, type }))
        }
    }

    const handleViewComplaintClick = async (item) => {
        const orderId = item.id;
        const baseURL = `${app_api.complaints}`;
        const endpoint = "/complaints";
        const url = `${baseURL}/${orderId}${endpoint}`;

        const res = await get_delete_request(
            'get',
            token,
            url,
            {},
            dispatch,
            "",
            "",
            "",
            true
        )
        console.log(res);
        if (res !== undefined && res.status < 400) {
            console.log('1111', res);

            setShowViewComplaintPopup(true);
            setShowDbPopup(false);

            setSelectedViewItem(res.data);
        };

    }
    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_sales}?deliveryStatus=PENDING&page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handlePageChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handlePageChange ~ pending_list:...................", pending_list);
            console.log("🚀 ~ handlePageChange ~ totalCount:...................", totalCount);

            setpayoutsByEventData(pending_list);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(pageNumber);
    };
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    // const viewComplaints = (item) => {
    //     setShowViewComplaintPopup(true);

    //     setSelectedItem(item);
    // }
    const handleSearchChange = async (event) => {
        // const value = event.target.value;
        // setSearchTerm(value);
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_sales}?deliveryStatus=PENDING&search=${searchTerm}&page=1&pageSize=${pageSize}&draw=1&q=&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handleSearchChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handleSearchChange ~ pending_list:...................", pending_list);
            console.log("🚀 ~ handleSearchChange ~ totalCount:...................", totalCount);

            setpayoutsByEventData(pending_list);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(1);
    }

    {/* Poupup design */ }

    // const [showPopup, setShowPopup] = useState(false);

    // const handleShowPopup = () => {
    //     debugger
    //   console.log('Button clicked, showing popup');
    //   setShowPopup(true);
    // };

    // const handleHidePopup = () => {
    //   console.log('Popup closed');
    //   setShowPopup(false);
    // };
    {/* Poupup design */ }
    return (

        <>
            {/* Poupup design */}
            {/* <AlertPopup show={showPopup} handleHide={handleHidePopup} /> */}
            {/* Poupup design */}

            <div style={{ columnGap: '0.5rem', rowGap: '0.6rem' }} className='d-flex flex-wrap'>
                {payoutEventId && isSuperAdmin &&
                    <button onClick={() => dispatch(showPayoutModalFunc({ modal: true, type: 'manage' }))} className='btn btn-sm btn-primary'>
                        Initiate Payout
                    </button>}
            </div>

            <div className="form-group search-input-top">
                <input autoComplete='off' type="text" className="form-control" id="" placeholder="Search..." onChange={handleInputChange} />
                <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={handleSearchChange}>Search</button>
                {/* Poupup design */}
                {/* <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={handleShowPopup}>alert</button> */}
                {/* Poupup design */}
            </div>


            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>Name</th>
                            <th scope="col" className='no-wrap'>Email</th>
                            <th scope="col" className='no-wrap'>Total Amount</th>
                            <th scope="col" className='no-wrap'>Payout Total Amount</th>
                            <th scope="col" className='no-wrap'>Payout Status</th>
                            <th scope="col" className='no-wrap'>Action</th>
                            <th scope="col" className='no-wrap'>Expand</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : payoutsByEventData.length > 0 ? (
                            payoutsByEventData.map((item, index) => (
                                <>
                                    <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                        <td>{item?.user?.name ?? ''}</td>
                                        <td><span class="text-ellipsis" title="${a?.user?.email}">{item?.user?.email} </span></td>
                                        <td>{strText.currency_symbol}{formatNumber(item?.amount)}</td>
                                        <td>
                                            {
                                                (() => {
                                                    // Calculate the due amount
                                                    const dueAmountRes = item?.finalAmount == 0 || item?.finalAmount == "" ? item?.amount : item?.finalAmount;

                                                    // Conditionally render based on `a.status`
                                                    if (item?.status !== "PAID") {
                                                        return (
                                                            <div className="input-group mb-4">
                                                                <div className="input-group-prepend d-flex align-items-center">
                                                                    <span>{strText.currency_symbol}</span>
                                                                    <input
                                                                        data-id={payoutEventId}
                                                                        className="form-control input-formamount change-amount"
                                                                        data-name={item?.userId}
                                                                        data-fname={item?.user?.name}
                                                                        type="text"
                                                                        value={formatNumber(dueAmountRes)}
                                                                        placeholder="Amount"
                                                                    />
                                                                    <div className="input-group-append save-btnamount">
                                                                        <button
                                                                            className="btn btn-primary save-amount"
                                                                            data-id={payoutEventId}
                                                                            data-name={item?.userId}
                                                                            data-fname={item?.user?.name}
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div className="input-group mb-4">
                                                                <label>
                                                                    {strText.currency_symbol} {formatNumber(item?.finalAmount)}
                                                                </label>
                                                            </div>
                                                        );
                                                    }
                                                })()
                                            }
                                        </td>

                                        <td> {item?.status === "NOT_INITIATED" ? "NOT INITIATED" : (item?.status ?? '')}</td>
                                        <td>
                                            {(() => {
                                                if (item?.status !== "PAID") {
                                                    const statusTypes = ["PROCESS", "HOLD", "SUSPEND"];
                                                    const preStatusType = item?.statusType;

                                                    return (
                                                        <div>
                                                            <select
                                                                data-id={payoutEventId}
                                                                data-name={item?.userId}
                                                                data-fname={item?.user?.name}
                                                                className="btn btn-sm select-setuserType btn-icon change-status-type"
                                                                defaultValue={preStatusType}
                                                            >
                                                                {statusTypes.map((type) => (
                                                                    <option key={type} value={type}>
                                                                        {type}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })()}
                                        </td>
                                        <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>

                                            {/* <a title='Request refund' className="btn btn-sm bg-danger text-white btn-icon request-refund" onClick={() => refundClick(index, item)}>
                                        <i class="fa fa-undo"></i>
                                    </a> */}
                                        </td>
                                    </tr>
                                    {console.log("expanded", expandedRows)}
                                    {(expandedRows[index] && item.orders.length > 0) && (
                                        <tr>
                                            <td colSpan="8">
                                                <table className="table table-striped tbl-inner-width">
                                                    <thead>
                                                        <tr>
                                                            <th className='no-wrap'>Order Id</th>
                                                            <th className='no-wrap'>No. Of Ticket</th>
                                                            <th className='no-wrap'>Ticket Price</th>
                                                            <th className='no-wrap'>Total Ticket Amount</th>
                                                            <th className='no-wrap'>Sale Date</th>
                                                            <th scope="col" className='no-wrap'>Final ordered Amount</th>
                                                            <th scope="col" className='no-wrap'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    {item.orders.map((item, orderIndex) => (
                                                        <tbody>
                                                            <tr key={orderIndex}>
                                                                <td>{item?.orderRef ?? ''}</td>
                                                                <td>{item?.totalNumberOfTickets || 0}</td>
                                                                <td>{item?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(item?.pricePerTicket)}` : 'N/A'}</td>
                                                                <td>{item?.totalAmountWithoutCommission ? `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission)}` : 'N/A'}</td>
                                                                <td>{moment(item?.createdAt).format(strText.full_date)}</td>
                                                                <td>
                                                                    {
                                                                        (() => {
                                                                            // Calculate the due amount
                                                                            const dueOrderAmountRes = item?.totalAmountWithoutCommission == 0 || item?.totalAmountWithoutCommission == "" ? item?.totalAmountWithoutCommission : item?.totalAmountWithoutCommission;

                                                                            // Conditionally render based on `a.status`
                                                                            if (item?.status !== "PAID") {
                                                                                return (
                                                                                    <div className="input-group mb-4">
                                                                                        <div className="input-group-prepend d-flex align-items-center">
                                                                                            <span>{strText.currency_symbol}</span>
                                                                                            <input
                                                                                                data-id={payoutEventId}
                                                                                                className="form-control input-formamount change-amount"
                                                                                                data-name={item?.userId}
                                                                                                data-fname={item?.user?.name}
                                                                                                type="text"
                                                                                                value={formatNumber(dueOrderAmountRes)}
                                                                                                placeholder="Amount"
                                                                                            />
                                                                                            <div className="input-group-append save-btnamount">
                                                                                                <button
                                                                                                    className="btn btn-primary save-amount"
                                                                                                    data-id={payoutEventId}
                                                                                                    data-name={item?.userId}
                                                                                                    data-fname={item?.user?.name}
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <div className="input-group mb-4">
                                                                                        <label>
                                                                                            {strText.currency_symbol} {formatNumber(item?.finalAmount)}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })()
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(() => {
                                                                        if (item?.status !== "PAID") {
                                                                            const statusTypes = ["PROCESS", "HOLD", "SUSPEND"];
                                                                            const preStatusType = item?.statusType;

                                                                            return (
                                                                                <div>
                                                                                    <select
                                                                                        data-id={payoutEventId}
                                                                                        data-name={item?.userId}
                                                                                        data-fname={item?.user?.name}
                                                                                        className="btn btn-sm select-setuserType btn-icon change-status-type"
                                                                                        defaultValue={preStatusType}
                                                                                    >
                                                                                        {statusTypes.map((type) => (
                                                                                            <option key={type} value={type}>
                                                                                                {type}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })()}
                                                                </td>
                                                            </tr>
                                                        </tbody>))}
                                                </table>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                    {/* {(show && type == 'confirm') &&
                    <ConfirmDelivery />
                } */}

                </table>
            </div>


            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}

