import { core_backend_url } from "../../config"

export const admin_api = {
    'add_user': `${core_backend_url}auth/register-user`,
    'get_users': `${core_backend_url}user/role/USER`,
    'get_admins': `${core_backend_url}user/admins`,
    'add_country': `${core_backend_url}country`,
    'add_city': `${core_backend_url}city`,
    'add_team': `${core_backend_url}team`,
    'add_venue': `${core_backend_url}venue`,
    'add_event': `${core_backend_url}event`,
    'add_promocode': `${core_backend_url}promoCode/create`,
    'add_competition': `${core_backend_url}competition`,
    'get_event_type': `${core_backend_url}event/types`,
    'get_all_events': `${core_backend_url}event/all-events`,
    'get_promocode_type': `${core_backend_url}promoCode/promoCodeTypes`,
    'add_promocode_type': `${core_backend_url}promoCode/addPromoCodeType`,
    'update_promocode_type': `${core_backend_url}promoCode/updatePromoCodeType`,
    'get_user_type': `${core_backend_url}event/usertypes`, //xskr
    'get_auth_user_type': `${core_backend_url}auth/usertypes`, //xskr
    'get_auth_status_type': `${core_backend_url}payout/statustypes`, //xskr
    'add_section': (venue_id) => `${core_backend_url}venue/${venue_id}/add-section`,
    'add_subsection': (section_id) => `${core_backend_url}venue/section/${section_id}/add-subsection`,
    'suspend_user': (user_id) => `${core_backend_url}user/${user_id}/suspend`,
    'change_user_type': (user_id,usertype) => `${core_backend_url}user/${user_id}/${usertype}/change-user-type`,
    'delete_user': (user_id) => `${core_backend_url}user/${user_id}/remove`,
    'update_venue': (venue_id) => `${core_backend_url}venue/${venue_id}`,
    'delete_venue': (venue_id) => `${core_backend_url}venue/${venue_id}`,
    'update_event': (event_id) => `${core_backend_url}event/${event_id}`,
    'update_promocode': (event_id) => `${core_backend_url}promoCode/${event_id}`,
    'delete_event': (event_id) => `${core_backend_url}event/${event_id}`,
    'update_team': (team_id) => `${core_backend_url}team/${team_id}`,
    'delete_team': (team_id) => `${core_backend_url}team/${team_id}`,
    'update_country': (country_id) => `${core_backend_url}country/${country_id}`,
    'delete_country': (country_id) => `${core_backend_url}country/${country_id}`,
    'update_city': (city_id) => `${core_backend_url}city/${city_id}`,
    'delete_city': (city_id) => `${core_backend_url}city/${city_id}`,
    'update_competition': (competition_id) => `${core_backend_url}competition/${competition_id}`,
    'delete_competition': (competition_id) => `${core_backend_url}competition/${competition_id}`,
    'update_section': (section_id) => `${core_backend_url}venue/section/${section_id}/update`,
    'delete_section': (section_id) => `${core_backend_url}venue/section/${section_id}/remove`,
    'update_subsection': (subsection_id) => `${core_backend_url}venue/section/subsection/${subsection_id}/update`,
    'delete_subsection': (subsection_id) => `${core_backend_url}venue/section/subsection/${subsection_id}/remove`,
    'get_listings': `${core_backend_url}listing`,
    'delete_listing': (listing_id) => `${core_backend_url}listing/${listing_id}`,
    'duplicate_listing': (listing_id) => `${core_backend_url}listing/${listing_id}/duplicate`,
    'toggle_listing_status': (listing_id) => `${core_backend_url}listing/${listing_id}/toggle-status`,
    'get_orders': `${core_backend_url}order/all`,
    'complaints': `${core_backend_url}order`,
    'get_all_sales' :  `${core_backend_url}sale/all`,
    'get_payouts_by_event': (event_id) => `${core_backend_url}payout/${event_id}`,
    'get_all_completed_events': `${core_backend_url}order/completedEvents`,
    'get_all_active_events': `${core_backend_url}order/activeEvents`,
    'get_payouts_by_event_latest': (event_id) => `${core_backend_url}payout/latest/${event_id}`,

    'get_payouts': `${core_backend_url}payout/due`,
    'initiate_payout': (eventId) => `${core_backend_url}payout/initiate/${eventId}`,
    'payout_status': `${core_backend_url}payout/payout_status`,
    'payout_amount': `${core_backend_url}payout/payout_amount`,
    'retry_payout': (eventId) => `${core_backend_url}payout/retry/${eventId}`,
    'get_refunds': `${core_backend_url}order/refund/requests`,
    'approve_refund': (refundRequestId)=>`${core_backend_url}order/refund/approve/${refundRequestId}`,
    'decline_refund': (refundRequestId)=>`${core_backend_url}order/refund/decline/${refundRequestId}`,
    'total_commission_revenue': `${core_backend_url}analytics/commission-revenue`,
    'manual_email': `${core_backend_url}manual_email`
}