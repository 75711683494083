import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
// import { FaTicketAlt } from "react-icons/fa";
// import { SiBlockbench } from "react-icons/si";
// import { ImPriceTags } from "react-icons/im";
import search_icon from '../../../../assets/images/search_icon.svg'
// import { MdAirlineSeatReclineExtra } from "react-icons/md";
import RestrictionsPage from '../../customer/buy_tickets/RestrictionsPage'
import loading_gif from '../../../../assets/images/logo-loading-gif-img.gif'
import {
  clearAll,
  logoutAppUser,
  onGetCart,
  setAppErrorMessage,
  setAppLoading,
  setCartValues,
  setData,
} from '../../../../config/redux/slice/appSlice'
import 'rc-slider/assets/index.css'
import { useNavigate } from 'react-router'
import { app_urls } from '../../../../utils/urls/app_urls'
import {
  logoutCustomerUser,
  setLastVisitedPage,
} from '../../../../config/redux/slice/customerSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { useForm } from 'react-hook-form'
import Spinner from '../../../components/Spinner'
import spinner_loader from '../../../../assets/images/Spinner-loading.gif'
import { logoutUser } from '../../../../config/redux/slice/authSlice'
import { logoutAdminUser } from '../../../../config/redux/slice/adminSlice'
import {
  formatNumber,
  setCartNo,
  transformTicketsList,
} from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import { auto } from '@popperjs/core'
import { useDebounce } from '../../../../hooks/useDebounce'
// import SmartPhone from "../../../../assets/images/smartphone.png"
import SmartPhone from "../../../../assets/images/e2.png"
import Physical from "../../../../assets/images/e8.png"
import Electric from "../../../../assets/images/electric.png"
import Electric1 from "../../../../assets/images/e1.png"
import MyListing from "../../../../assets/images/letter-l.png"
import { setCustomerErrorMessage, setCustomerLoading, onUpdate } from '../../../../config/redux/slice/customerSlice'
import { get_delete_request } from '../../../../helpers/request';

export default function EventTicket({
  listingList,
  makeanOfferClick,
  getRequestFirstPage,
  numberOfTickets,
  minPrice,
  maxPrice,
  openSeat,
  totalValueRecords,
  paginationData,
  listLoading,
  getRequest,
  filterTickets,
  modifyListingObj,
  filterIds,
  pagePath,
  isFetchAllListings,
  initialPriceChange
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [path] = useState(window.location.pathname)
  const [listingList1, setListingList] = useState([])
  const [openNegotiationForm, setOpenNegotiationForm] = useState(false)
  const [listingId, setListingId] = useState('')
  const [ticketIdNegotiate, setTicketIdNegotiate] = useState('')
  const [isAtBottom, setIsAtBottom] = useState(false)
  const [pageNo, setPageNo] = useState(paginationData?.page)
  const [isAddingToCart, setIsAddingToCart] = useState({})
  const isAtBottomDebounced = useDebounce(isAtBottom, 200)
  const [isOfferVisible, setIsOfferVisible] = useState(false);
  const [showDbPopup, setShowDbPopup] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const appLoading = useSelector((state) => state.app.appappLoading)
  const listingsInCart = useSelector(
    (state) => state.app.cartManagement.listingsInCart
  )
  const searchingTickets = useSelector(
    (state) => state.app.cartManagement.searchingTickets
  )
  const { token, userData } = useSelector((state) => state.authData)
  const scrollContainerRef = useRef(null)

  useEffect(() => {
    if (isAtBottomDebounced && Number(totalValueRecords) > listingList.length) {
      setPageNo((prevState) => prevState + 1)
      let data = {
        page: pageNo + 1,
        pageSize: paginationData?.pageSize || 10,
        ...filterIds,
      }
      getRequest(data)
    }
  }, [
    isAtBottomDebounced,
    filterIds,
    listingList.length,
    paginationData,
    pageNo,
    totalValueRecords,
    getRequest,
  ])
  useEffect(() => {
    const result = listingList.flatMap((listingOption) => {
      console.log('listing', listingOption);
      // console.log('userData', userData);
      // Transform tickets list for each listing option
      const transformedTickets = transformTicketsList(
        listingOption?.Tickets,
        listingOption,
        totalValueRecords,
        numberOfTickets,
        minPrice,
        maxPrice,
        userData.user_role
      );
{console.log("transssssssssssssssss",transformedTickets)}
      return transformedTickets.map((ticket) => ({
        ...ticket,
        totalPrice: ticket.numberOfTickets * ticket.price,
        userId: listingOption.userId,
        sellingOption:listingOption.sellingOption,
        ticketsType: listingOption.ticketsType,
        hasFile: listingOption.Tickets.every(ticket => ticket.file),
        sectionName: listingOption?.section?.name ? listingOption?.section?.name : listingOption?.sectionName,
        subSectionName: listingOption?.subSection?.name ? listingOption?.subSection?.name : listingOption?.subSectionName,
        restrictionsAndFeatures: listingOption?.restrictionsAndFeatures,
        priceWithoutCommission: listingOption?.pricePerTicketWithoutCommission
      }));
    });


    const flatResult = result.flat();
    console.log(flatResult, "newwwwwwwwwwwwwwwwwwwwbbbbbbbbbbbbbbbbbwwww11111", result);

    const sortedResult = flatResult.sort((a, b) => a.price - b.price);
    console.log("sorted", sortedResult);
    setSelectedCheckboxes(sortedResult);
  }, [listingList, minPrice, maxPrice]);

  useEffect(()=>{
    getAllEvents()
  },[userData])

  const getAllEvents = async () =>{

    const isAuthenticated = userData && userData.email;
    const tokenValue = isAuthenticated ? token : '';
    const endpoint = isAuthenticated ? `${app_api.get_trending_events_auth}` : `${app_api.get_trending_events}`;

    console.log("userData",userData)
    console.log("userData",userData)

      dispatch(clearAll({}))
      // const res = await get_delete_request(
      //   'get',
      //   tokenValue,
      //   `${app_api.get_trending_events}`,
      //   {},
      //   dispatch,
      //   "",
      //   "",
      //   ""
      // )
      // console.log('res', res);
      // if(res !== undefined && res.status < 400){
      //   dispatch(setData({data: res?.data?.data, type: 'event'}))
      // }
      try {
        const res = await get_delete_request(
          'get',
          tokenValue,
          endpoint,
          {},
          dispatch,
          "",
          "",
          ""
        );
        if (res !== undefined && res.status < 400) {
          console.log('events123', res.data);
          dispatch(setData({ data: res?.data?.data, type: 'event' }));
        } else {
          console.error('Error fetching events:', res?.data ?? 'No response data');
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };


  const handleRestrictionClick = (item) => {
    if (item && item !== '') {
      setShowDbPopup(true)
      setSelectedItem(item);
    }
  }
  const logout = () => {
    dispatch(logoutUser())
    dispatch(logoutCustomerUser())
    dispatch(logoutAppUser())
    dispatch(logoutAdminUser())
    navigate(app_urls.login)
  }

  const setCartData = async (data) => {

    dispatch(onGetCart(data))
    dispatch(setData({ data: data, type: 'cart' }))
    let cartNo = setCartNo(data)
    dispatch(setCartValues({ propertyKey: 'cartNumber', value: cartNo }))
  }


  const handleTraderAction = async (details) => {
    setIsLoading(true);
    const requestData = {
      ticketIds: details.ticketIds,
    }
    const res = await post_put_patch_request(
      "post",
      token,
      `${app_api.create_order}`,
      requestData,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
    )
    if (res && res.status < 400) {
      console.log('status', res.status);
      navigate(app_urls.payment_successful)
    }
    else {
      dispatch(onUpdate({ message: res?.data?.message, type: 'sales' }))
    }
  }

  const addToCart = async (ticketDetails, sellTogether, amount, index) => {
    dispatch(clearAll({}));

    setIsAddingToCart({ [index]: true });

    if (token === '') {
      dispatch(setLastVisitedPage(path));
      navigate(app_urls.login);
    } else {
      let tickets = ticketDetails.Tickets.map(ticketOption => ({
        ticketId: ticketOption.id,
        amount: Number(amount),
      }));
      // removeBoughtTickets(ticketDetails);
      // setTest(ticketDetails)
      let data = { tickets };

      const res = await post_put_patch_request(
        'patch',
        token,
        `${app_api.add_to_cart}`,
        data,
        dispatch,
        setAppLoading,
        '',
        setAppErrorMessage
      );
      if (res && res.status < 400) {
        setCartData(res.data.data);
        setIsAddingToCart({ [index]: false });

        toast.success("Congrats! Ticket(s) have been added to your basket", {
          position: toast.POSITION.TOP_CENTER,
        });
        proceed()

      } else if (res && res.status === 401) {
        dispatch(setLastVisitedPage(pagePath));
        logout();
      }
      else if (res && res.status === 400) {
        setIsAddingToCart({ [index]: false });
        toast.error("The ticket(s) are owned by the user", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        setIsAddingToCart({ [index]: false });
      }
    }
  };
  const proceed = () => {
    navigate(app_urls.buy_ticket)
  }


  const submitForm = (listingobj, listingObjId) => {
    console.log('listingobj', listingobj);
    console.log('listingObjId', listingObjId);
    modifyListingObj(listingobj, listingObjId)
    
  }

  const handleScroll = () => {
    const element = scrollContainerRef.current

    // Calculate the distance between the bottom of the content and the current scroll position
    const distanceToBottom =
      element.scrollHeight - element.scrollTop - element.clientHeight

    // Check if the distance to the bottom is very small (considering potential rounding errors)
    const atBottom = distanceToBottom < 1
    setIsAtBottom(atBottom)
  }


  let sectionValue = document.getElementById('section') ? document.getElementById('section').value : "";
  let sectionName = sectionValue === "" ? 'all' : listingList[0]?.section?.name;
  return (
    <>
      <ToastContainer />
      {searchingTickets ? (
        <div
          style={{ fontSize: '0.9rem', height: '60%' }}
          className={`w-100 d-flex justify-content-center align-items-center`}
        >
          {/* <img height={30}  src={loading_gif} alt="loader" /> */}
          {/* <img height={30}  src={spinner_loader} alt="loader" /> */}
          Please Wait. Searching Tickets...
        </div>
      ) : (
        <>
        {console.log("teessssssssssssssssssssssss",openSeat ,listingList.length )}
          {((openSeat == true || listingList.length == 0 ) && makeanOfferClick === "false") ? (
            <>
              <p className="mx-3 mx-lg-2 mx-xl-3 text-center">No ticket was found</p>
            </>
          ):
          ((openSeat == true || listingList.length == 0 ) && makeanOfferClick === "true") ? (
            <>
              <p className="mx-3 mx-lg-2 mx-xl-3 text-center">No tickets Available. Make another offer - <span  onClick={() => {
                                              getRequestFirstPage('')
                                              setOpenNegotiationForm(false)
                                              setTicketIdNegotiate('')
                                            }}> <img  style={{cursor:'pointer'}} width="20px" height="20px" className = "searchIcon-header" src={search_icon} alt="aaaaaaaaaaaaaaaaa" /></span></p>
            </>
          ) : (
            <>
              {/* <h6 className="mx-3 mx-lg-2 mx-xl-3 ticket-textevent mb-1">
                Results showing for{' '}
                <b className='bold-ticket-color'>
                  "{sectionName}"
                </b>{' '}
                {sectionValue === "" ? 'sections' : 'section'}
              </h6> */}

              {/* <button className='btn btn-accent mb-2 text-white'>Negotiate</button>
                <div>
                  <Slider
                    range
                    value={range}
                    onChange={handleSliderChange}
                    min={0}
                    max={100}
                    step={1}
                  />
                  <p>
                    Range: {range[0]} - {range[1]}
                   </p
                </div> */}

              <div
                ref={scrollContainerRef}
                onScroll={handleScroll}
                style={{
                  // height: '140vh',
                  // overflowY: auto
                }}
                className="pr-3  event-ticket-scroll-container"
              >

{console.log(listingList,"selectedCheckboxesselectedCheckboxes",selectedCheckboxes,openSeat)}
                {selectedCheckboxes.map((option, index) => {
                  const isElectronic = option.ticketsType === 'ELECTRONIC';
                  const hasFile = (option.hasFile != null && option.hasFile);


                  // <> 
                  return (
                    <React.Fragment key={option.id}>
                      {/* {selectedCheckboxes.map((option, index) => { */}

                      {/* return ( */}
                      <React.Fragment key={option.id}>

                        {listingsInCart.includes(option.id) === false && (
                          <div className="ticket3">
                            {/* <span
                              className={`badge badge-left-ticket text-white me-2 mb-1 ${option?.ticketIds.length > 1
                                ? 'bg-success'
                                : 'bg-danger'
                                }`}
                            >
                              {option?.ticketIds.length > 1
                                ? 'Group'
                                : 'Single'}
                            </span> */}
                            {/* {console.log(userData,"optionnnnnnnnnnnnnn", listingOption)} */}


                            <div className="ticket3__details" style={{ borderLeft: "4px solid #f3af5e" }}>
                              {/* {option.userId != userData.id &&
                                // <span className={`badge badge-right-ticket text-white me-2 mb-1 bg-grey ${(!hasFile || !isElectronic) ? 'right-0' : ''}`}>My listing</span>}
                                <span className="badge badge-right-ticket text-white me-2 mb-1 " title='My listing'><img src={MyListing} alt="MyListing" className="img-fluid" width={20} height={18} /></span>} */}










                              {/* {isElectronic && hasFile && (
                                <span className={`instantdownload-ticket ${option.userId === userData.id ? 'disabledInstant' : ''}`} title='Instant Download'
                                  onClick={() => {
                                    setOpenNegotiationForm(false)

                                    setTicketIdNegotiate('')

                                  }}>
                                  <i className="fa fa-download" aria-hidden="true"></i>
                                </span>
                              )} */}
                              <ul>

                                <li className='section-text pt-1' title={option?.sectionName}>
                                  {/* <b>Section:</b>  */}
                                  <span className='secName-event'>{option?.sectionName}</span></li>
                                <li className='block-text-sec'>
                                  <div className="">
                                    {/* <div className="">Block: {option?.subSectionName}</div> */}
                                    {/* <div></div> */}
                                  </div>

                                  {/* <div className="position-relative d-flex align-items-center">
                                        <div className="me-2">Price:</div>
                                        {option?.negotiatedPrice ? (
                                          <div className="d-flex align-items-center">
                                            <span className="bottom-price mb-1 me-2">
                                              {strText.currency_symbol}
                                              {formatNumber(option?.negotiatedPrice)}
                                            </span>
                                            <span className="text-decoration-line-through">
                                              {strText.currency_symbol}
                                              {formatNumber(option?.price)}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="d-flex align-items-center">
                                            {strText.currency_symbol}
                                            {formatNumber(option?.price)}
                                          </div>
                                        )}
                                      </div> */}
                                </li>
                                <li className='block-text-sec text-left'>
                                  <div className="">
                                    <div className="">
                                      {/* No. of Tickets:  */}
                                      <span className="ticket-subtext"> {option?.detailsList[0]?.row !== "-" && option?.detailsList[0]?.row !== null && option?.detailsList[0]?.row !== "" ? `Row ${option?.detailsList[0]?.row} | ` : ""}
                                        {option?.numberOfTickets} {option?.numberOfTickets === 1 ? 'ticket' : 'tickets'}

                                      </span>
                                    </div>

                                  </div>
                                  {/* <div className="seats-sec-ticket">
                                        <div>Seats</div>
                                        {console.log("ccccccccc", option?.seatNos)}
                                        <div>

                                          {option?.seatNos?.map((singleItem, idx) => {
                                            console.log("cvvvv", singleItem);
                                            return <span key={idx} className="ticket-card">{singleItem}</span>;
                                          })}
                                         
                                        </div>
                                      </div> */}
                                </li>

                                <li>
                                  <div className="row w-100 pb-1">
                                    <div className="col-md-3 d-flex" style={{ paddingRight: "0" }}>
                                      <div>
                                        {option?.ticketsType === 'MOBILE' && (
                                          <img src={SmartPhone} alt="SmartPhone" className="img-fluid" width={20} height={18} title="Mobile Ticket"/>
                                        )}
                                        {option?.ticketsType === "ELECTRONIC" && (
                                          <img src={Electric1} alt="Electric" className="img-fluid" width={20} height={18} title="Electronic Ticket"/>
                                        )}
                                        {option?.ticketsType === "PHYSICAL" && (
                                          <img src={Physical} alt="Physical" className="img-fluid" width={20} height={18} title="Physical Ticket"/>
                                        )}
                                      </div>

                                      <div className="" style={{ marginLeft: "2px" }}>
                                        {isElectronic && hasFile && (
                                          <span className={` ${option.userId === userData.id ? 'disabledInstant' : ''}`} title='Instant Download'
                                            onClick={() => {
                                              setOpenNegotiationForm(false)

                                              setTicketIdNegotiate('')

                                            }}>
                                            <img src={Electric} alt="Electric" className="img-fluid" width={20} height={18} />
                                          </span>
                                        )}

                                        {option.userId == userData.id &&
                                          <span className=""  style={{ marginLeft: "4px" }} title='My listing'><img src={MyListing} alt="MyListing" className="img-fluid" width={20} height={18} /></span>}
                                      </div>
                                    </div>

                                    <div className="col-md-5 p-0" style={{ textAlign: "right" }}>
                                      {option?.restrictionsAndFeatures && option.restrictionsAndFeatures.trim() !== '' && (
                                        <span
                                          className='makeAnoffer-sec'
                                          onClick={() => handleRestrictionClick(option.restrictionsAndFeatures)}
                                        >
                                          Restrictions & Features
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-md-4" style={{ paddingRight: "0", textAlign: "right" }}>
                                    <span
        className="makeAnoffer-sec"
        onClick={() => {
          if (ticketIdNegotiate === option.id) {
            // If the clicked row's form is already open, close it
            setOpenNegotiationForm(false);
            setTicketIdNegotiate('');
          } else {
            // Open the form for the clicked row and close any previously open form
            setOpenNegotiationForm(true);
            setTicketIdNegotiate(option.id);
          }
        }}
      >
        Make an offer
      </span>
                                    </div>
                                  </div>
                                </li>

                                {/* <li className='block-text-sec text-left'>
                                      <span className='makeAnoffer-sec'  onClick={() => {
                                          setOpenNegotiationForm(
                                            !openNegotiationForm
                                          )

                                          setTicketIdNegotiate(option.id)
                                        }}>Make an offer</span>
                                    </li> */}


                                {/* <div className='makeAnoffer-sec-botm'>
                                  {option?.restrictionsAndFeatures && option.restrictionsAndFeatures.trim() !== '' && (

                                    <span
                                      className='makeAnoffer-sec ofr-link-left'
                                      onClick={() => handleRestrictionClick(option.restrictionsAndFeatures)}
                                    >
                                      Restrictions & Features
                                    </span>
                                  )}
                                  {ticketIdNegotiate !== option.id && (
                                    <span
                                      className='makeAnoffer-sec ofr-link-right'
                                      onClick={() => {
                                        setOpenNegotiationForm(
                                          !openNegotiationForm
                                        )

                                        setTicketIdNegotiate(option.id)
                                      }}
                                    >
                                      Make an offer
                                    </span>
                                  )}
                                </div> */}
                                {openNegotiationForm &&
                                  ticketIdNegotiate === option.id && (
                                    <NegotiationForm
                                      ticketDetails={option}
                                      ticketIds={option.ticketIds}
                                      isSellTogether={false}
                                      sellOption={'AVOID_LEAVING_SINGLE'}
                                      filterTickets={filterTickets}
                                      makeanOfferClick = {makeanOfferClick}
                                      getRequestFirstPage={getRequestFirstPage}
                                      listingId={option.id}
                                      submitForm={submitForm}
                                      onClick={() => {
                                        setOpenNegotiationForm(false)
                                        setTicketIdNegotiate('')
                                      }}
                                      onClose={() => {
                                        setOpenNegotiationForm(false)
                                        setTicketIdNegotiate('')
                                      }}
                                    />
                                  )}
                                {/* {openNegotiationForm &&
                                      ticketIdNegotiate === option.id && (
                                      <li className='block-text-sec text-left mt-0'>
                                        <div class="form-group block-textinput mt-0">
                                          <label for="" className='labl-textoffer mt-0'>Enter Price <sup className='star-red'>*</sup></label>
                                          <div className="d-flex align-items-center">
                                            <input type="text" className="form-control" id="" placeholder="Enter Price" />
                                            <button className='btn-submitoffer' title='Submit Offer'>Submit</button>
                                            <button className='btn-closeoffer' title="close" onClick={handleCloseClick}>Close</button>
                                          </div>
                                        </div>
                                      </li>
                                    )} */}

                              </ul>

                            </div>
                            <div className="ticket3__rip"></div>
                            <div className="ticket3__price">{console.log('opt', option)}
                              {userData?.user_role === 'TRADER' ? (
                                <div className="align-items-center" style={{ textAlign: "right", lineHeight: "13px", fontSize: "13px" }}>
                                  {strText.currency_symbol}
                                  {formatNumber(option?.priceWithoutCommission)} <br /> <span className="totalprice-text">/each</span>
                                </div> 
                              ) : (
                                option?.negotiatedPrice ? (
                                  <div className="">
                                    <div className="bottom-price " style={{ textAlign: "right", lineHeight: "13px", fontSize: "13px" }}>
                                      {strText.currency_symbol}
                                      {formatNumber(option?.negotiatedPrice)}<span className="totalprice-text">/each</span>
                                    </div>
                                    <div className="text-decoration-line-through" style={{ textAlign: "right", lineHeight: "13px", fontSize: "13px" }}>
                                      {strText.currency_symbol}
                                      {formatNumber(option?.price)}<span className="totalprice-text">/each</span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="align-items-center" style={{ textAlign: "right", lineHeight: "13px", fontSize: "13px" }}>
                                    {strText.currency_symbol}
                                    {formatNumber(option?.price)} <br /> <span className="totalprice-text">/each</span>
                                  </div>
                                )
                              )}

                              {/* 
                              {option?.negotiatedPrice ? (
                                <div className="d-flex align-items-center">
                                  <span className="bottom-price mb-1 me-2">
                                    {strText.currency_symbol}
                                    {formatNumber(option?.negotiatedPrice)}
                                  </span>
                                  <span className="text-decoration-line-through">
                                    {strText.currency_symbol}
                                    {formatNumber(option?.price)}
                                  </span>
                                </div>
                              ) : (
                                <div className="align-items-center" style={{ textAlign: "right", lineHeight: "13px", fontSize: "13px" }}>
                                  {strText.currency_symbol}
                                  {formatNumber(option?.price)} <br /> <span className="totalprice-text">/each</span>
                                </div>
                              )} */}


                              <button className='btn-buy' onClick={() => {
                                if (userData?.user_role === 'TRADER') {
                                  handleTraderAction(option); // Call the function for TRADER role
                                } else {
                                  // Continue with the existing logic for other roles
                                  setOpenNegotiationForm(false);
                                  setTicketIdNegotiate('');
                                  addToCart(
                                    {
                                      ...option,
                                      custom_id: option.id,
                                      Tickets: option.detailsList,
                                      optionDetails: option
                                    },
                                    'neither',
                                    option?.negotiatedPrice ?? option?.price,
                                    index
                                  );
                                }
                              }}
                                disabled={option.userId === userData.id}
                              >{isAddingToCart?.[index] || isLoading ? (
                                <Spinner
                                  color="text-dark"
                                  spinner_styles={{
                                    width: '1rem',
                                    height: '1rem',
                                  }}
                                />
                              ) : (
                                'Buy Now'
                              )}</button>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                      {/* ) */}
                      {/* })} */}
                    </React.Fragment>
                  )
                })}
              </div>
              <RestrictionsPage setShowDbPopup={setShowDbPopup} showDbPopup={showDbPopup} selectedItem={selectedItem} />

            </>
          )}
        </>
      )}
    </>
  )
}

const NegotiationForm = ({
  onClick,
  submitForm,
  ticketDetails,
  isSellTogether,
  filterTickets,
  makeanOfferClick,
  getRequestFirstPage,
  ticketId,
  ticketIds,
  listingId,
  sellOption,
  onClose,
}) => {
  const [negotiationLoading, setNegotiationLoading] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [negotiatedPrice, setNegotiatedPrice] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched' })



  const onSubmit = (data) => {
    setNegotiationLoading(true)
    setSuccessMsg(false)
    setErrorMsg(false)

    {console.log(parseFloat(data.amount),"bhbbbbbhhhbhhh",ticketDetails)}
    if ((parseFloat(data.amount) >= ticketDetails?.reservedPricePerTicket) && (parseFloat(data.amount) <= ticketDetails?.pricePerTicket)) {
      let listingobj;
      let newTicketList = ticketDetails?.detailsList.map((opt) => {
        if (ticketIds.includes(opt.id)) {
          return { ...opt, negotiatedPrice: data.amount }
        } else {
          return opt
        }

      })
      console.log('newTicketList', newTicketList);
      listingobj = { ...ticketDetails, Tickets: newTicketList }
      submitForm(listingobj, listingId)
     
      setTimeout(() => {
        setNegotiationLoading(false); // Hide loading after 500ms
        setSuccessMsg(true);          // Show success message at the same time
      
        // setTimeout(() => {
        //   // onClose();                  // Close after 2000ms
        // }, 4000);                     // Delay of 2000ms for closing the modal after success
      }, 500); 
     
    } else {
      setNegotiatedPrice(data.amount)
      setTimeout(() => {
        setErrorMsg(true)
        setNegotiationLoading(false)
      }, 1500)
    }
  }

  return (
    <>




      {/* <li className='block-text-sec text-left mt-0'>
                                        <div class="form-group block-textinput mt-0">
                                          <label for="" className='labl-textoffer mt-0'>Enter Price <sup className='star-red'>*</sup></label>
                                          <div className="d-flex align-items-center">
                                            <input type="text" className="form-control" id="" placeholder="Enter Price" />
                                            <button className='btn-submitoffer' title='Submit Offer'>Submit</button>
                                            <button className='btn-closeoffer' title="close" onClick={handleCloseClick}>Close</button>
                                          </div>
                                        </div>
                                      </li> */}





      {/* <hr className="my-4" /> */}
      <div className="position-relative negotiate-form">
        {!negotiationLoading && !successMsg && !errorMsg && (
          <div className="">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="form-group block-textinput mt-0">
                <label htmlFor="address" className='labl-textoffer mt-0'>
                  Enter Price <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  //   max={moment().format('YYYY-MM-DD')}
                  className="form-control input-lg"
                  id="address"
                  {...register('amount', {
                    required: {
                      value: true,
                      message: '*this field is required',
                    },
                  })}
                />
                {errors?.amount?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.amount?.message}
                  </div>
                )}
              </div>
              <div className="mt-2 mb-2 d-flex align-items-center">
                <div>
                  {negotiationLoading ? (
                    <Spinner isForm={true} />
                  ) : (
                    <button type="submit" className="btn-submitoffer" title='Submit Offer'>
                      Submit
                    </button>
                  )}
                </div>
                <button
                  type="button"
                  title="close"
                  onClick={onClick}
                  className="btn-closeoffer"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        )}
        {negotiationLoading && (
          <div
            style={{ fontSize: '0.9rem' }}
            className={`w-100 d-flex justify-content-center align-items-center`}
          >
            <img height={30} src={spinner_loader} alt="loader" /> Negotiating
            ...
          </div>
        )}
        {successMsg && (
          <div className={`w-100 px-2`}>
            <div className="d-flex justify-content-center align-items-center">
              <i
                style={{ fontSize: '1.5rem' }}
                className={`mdi me-1 mdi-checkbox-marked-circle text-success`}
              ></i>
              <p
                style={{ fontSize: '12px' }}
                className={`text-success text-center text-capitalize`}
              >
                Seller accepted your price!
              </p>
            </div>
            <div className="text-center mb-2">
              <button onClick={onClose} className="btn-closeoffer">
                Close
              </button>
            </div>
          </div>
        )}
        {errorMsg && (
          <div className={`w-100 px-2`}>
            <div className="d-flex justify-content-center">
              <i
                style={{ fontSize: '1.5rem' }}
                className={`mdi me-1 mdi-close-circle text-danger`}
              ></i>
              <p
                style={{ fontSize: '0.9rem' }}
                className={`text-danger text-center text-capitalize`}
              >
               Reserved Price not met. Please make another offer or try other listings
              </p>
            </div>
            <div className="px-2 d-flex align-items-center justify-content-center gap-2 flex-wrap mt-2">
              <button
                onClick={() => {
                  filterTickets(negotiatedPrice,"makeAnOfferClick")
                }}
                className="btn btn-primary"
              >{    console.log('negotiatedPrice', negotiatedPrice)
}
                Show tickets for {strText.currency_symbol}
                {formatNumber(negotiatedPrice)} and below
              </button>
              <button onClick={onClose} className="btn ticket-btn">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
