const base = '/admin'

export const admin_urls = {
    'admin_dashboard': `${base}`,
    'users_admin': `${base}/users`,
    'admin_admin': `${base}/system-users`,
    'stadium_admin': `${base}/venue`,
    'events_admin': `${base}/events`,
    'listings_admin': `${base}/listings`,
    'payouts_due_admin': `${base}/payouts`,
    'payouts_due_admin_latest': `${base}/payoutslatest`,
    'order_refunds': `${base}/refunds`,
    'payouts_admin': `${base}/payouts/manage-payouts`,
    'payouts_admin_latest': `${base}/payouts/manage-payouts_latest`,
    'orders_admin': `${base}/orders`,
    //'listings_admin1': `${base}/listings1`,
    'countries_admin': `${base}/countries`,
    'cities_admin': `${base}/cities`,
    'teams_admin': `${base}/teams`,
    'competitions_admin': `${base}/competitions`,
    'sections_admin': `${base}/venue/manage-sections`,
    'subsections_admin': `${base}/venue/manage-subsections`,
    'profile_admin': `${base}/profile`,
    'refunds': `${base}/refunds`,
    'manual_email': `${base}/manual-email`,
    'promo_code': `${base}/promo-code`
    // 'order_items_admin':`${base}/orders/items`,
}