import ContactPage from "../../app/layout/ContactPage";
import LoadingPage from "../../app/layout/LoadingPage";
import { AboutUsPage, BuyersPage, HomePage, SellersPage } from "../../app/pages/website";
import EventPage from "../../app/pages/website/events/EventPage";
import CookiePolicy from "../../app/pages/website/policies/CookiePolicy";
import PrivacyPolicy from "../../app/pages/website/policies/PrivacyPolicy";
import TermsAndCondition from "../../app/pages/website/policies/TermsAndCondition";
import { app_urls } from "../../utils/urls/app_urls";
import RefundPolicy from "../../app/pages/website/policies/RefundPolicy";

export const guest_routes = [
    {
		url: app_urls.home,
		component: <HomePage />,
		routeType: 'private'
	},
	{
		url: app_urls.about_us,
		component: <AboutUsPage />,
		routeType: 'private'
	},
	{
		url: app_urls.sellers,
		component: <SellersPage />,
		routeType: 'private'
	},
	{
		url: app_urls.buyers,
		component: <BuyersPage />,
		routeType: 'private'
	},
	{
		url: app_urls.single_event,
		component: <EventPage />,
		routeType: 'private'
	},
	{
		url: app_urls.google_auth,
		component: <LoadingPage />,
		routeType: 'private'
	},
	{
		url: app_urls.facebook_auth,
		component: <LoadingPage />,
		routeType: 'private'
	},
	{
		url: app_urls.apple_auth,
		component: <LoadingPage />,
		routeType: 'private'
	},
	{
		url: app_urls.cookie_policy,
		component: <CookiePolicy />,
		routeType: 'private'
	},
	{
		url: app_urls.terms_and_condition,
		component: <TermsAndCondition />,
		routeType: 'private'
	},
	{
		url: app_urls.refund_policy,
		component: <RefundPolicy />,
		routeType: 'private'
	},
	{
		url: app_urls.privacy_policy,
		component: <PrivacyPolicy />,
		routeType: 'private'
	},
	{
		url: app_urls.contact_us,
		component: <ContactPage />,
		routeType: 'private'
	},
 ]