import AdminManagement from "../../app/pages/admin/admin_management/AdminManagement";
import CityManagement from "../../app/pages/admin/city_management/CityManagement";
import CompetitionManagement from "../../app/pages/admin/competition_management/CompetitionManagement";
import CountryManagement from "../../app/pages/admin/country_management/CountryManagement";
import { Dashboard } from "../../app/pages/admin/dashboard";
import EventManagement from "../../app/pages/admin/event_management/EventManagement";
import ListingManagement from "../../app/pages/admin/listing_management/ListingManagement";
import ManualEmail from "../../app/pages/admin/manual_email/manualEmail";
import OrderManagement from "../../app/pages/admin/orders_management/OrderManagement";
import PayoutDueManagement from "../../app/pages/admin/payouts_management/PayoutDueManagement";
import PayoutDueManagementNew from "../../app/pages/admin/payouts_management/PayoutDueManagementNew";
import PayoutManagement from "../../app/pages/admin/payouts_management/PayoutManagement";
import PayoutManagementNew from "../../app/pages/admin/payouts_management/PayoutManagementNew";
import Profile from "../../app/pages/admin/profile/Profile";
import PromoCode from "../../app/pages/admin/promo_code/promoCode";
import RefundManagement from "../../app/pages/admin/refund_management/RefundManagement";
import SectionManagement from "../../app/pages/admin/section_management/SectionManagement";
import StadiumManagement from "../../app/pages/admin/stadium_management/StadiumManagement";
import SubsectionManagement from "../../app/pages/admin/subsection_management/SubsectionManagement";
import TeamManagement from "../../app/pages/admin/team_management/TeamManagement";
import UserManagement from "../../app/pages/admin/user_management/UserManagement";
import { app_urls } from "../../utils/urls/app_urls";

export const admin_routes = [ 
    {
		url: app_urls.admin_dashboard,
		component: <Dashboard />,
		routeType: 'private'
	},
	{
		url: app_urls.users_admin,
		component: <UserManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.admin_admin,
		component: <AdminManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.stadium_admin,
		component: <StadiumManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.events_admin,
		component: <EventManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.manual_email,
		component: <ManualEmail />,
		routeType: 'private'
	},
	{
		url: app_urls.promo_code,
		component: <PromoCode />,
		routeType: 'private'
	},
	{
		url: app_urls.listings_admin,
		component: <ListingManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.orders_admin,
		component: <OrderManagement />,
		routeType: 'private'
	},
	// {
	// 	url: app_urls.listings_admin1,
	// 	component: <ListingManagement />,
	// 	routeType: 'private'
	// },
	{
		url: app_urls.countries_admin,
		component: <CountryManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.cities_admin,
		component: <CityManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.teams_admin,
		component: <TeamManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.competitions_admin,
		component: <CompetitionManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.sections_admin,
		component: <SectionManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.subsections_admin,
		component: <SubsectionManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.profile_admin,
		component: <Profile />,
		routeType: 'private'
	},
	{
		url: app_urls.listings_admin,
		component: <ListingManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.payouts_due_admin,
		component: <PayoutDueManagement />,
		routeType: 'private'
	},
	{
		url: app_urls.payouts_due_admin_latest,
		component: <PayoutDueManagementNew />,
		routeType: 'private'
	},
	{
		url: app_urls.payouts_admin,
		component: <PayoutManagement/>,
		routeType: 'private'
	},
	{
		url: app_urls.payouts_admin_latest,
		component: <PayoutManagementNew/>,
		routeType: 'private'
	},
	{
		url: app_urls.order_refunds,
		component: <RefundManagement />,
		routeType: 'private'
	},
]
