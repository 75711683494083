import React, { useEffect, useState } from 'react'
import ModalLayout from '../../../layout/ModalLayout'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setCustomerErrorMessage, setCustomerLoading, setListingValues, showListingModalFunc, showTicketModalFunc } from '../../../../config/redux/slice/customerSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import { strText } from '../../../../constants/english'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import { format } from 'date-fns';
export default function EditListing({ getList }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { register, handleSubmit, watch, clearErrors, getValues, setError, resetField, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })

    const {
        token
    } = useSelector((state) => state.authData)

    // const {
    //     customerLoading,
    //   listingManagement: {listingDetails}
    // } = useSelector((state) => state.customer)

    const {
        customerLoading,
        listingManagement: { singleListingDetails, listingDetails }
    } = useSelector((state) => state.customer)



    const [seats, setSeats] = useState([])
    const [removedSeats, setRemovedSeats] = useState([])
    const [subSections, setSubSections] = useState([])
    const [sectionsData, setSections] = useState([])
    const [showError, setShowError] = useState(false)
    const [errorType, setErrorType] = useState('')
    const [activeTab, setActiveTab] = useState(1)

    const rowValue = watch('row')
    const seatNoValue = watch('seatNumber')
    const ticketNoValue = watch('numberOfTickets')
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [checkboxLabels, setCheckboxLabels] = useState([]);
    const [hasRestrictions, setHasRestrictions] = useState(false);
    const [activeButton, setActiveButton] = useState(null);

    const allLabels = [
        'Restricted view', 'Child ticket (ages 16 and under)*', 'Child ticket (ages 18 and under)*',
        'Junior ticket (ages 21 and under)', 'Senior ticket (61 and older)',
        'Away Supporters Only', 'Home Supporters Only', 'Includes unlimited food and drinks',
        'Includes limited complimentary food and drinks', 'Food and drink voucher included',
        'Free Halftime drinks', 'Complimentary matchday programme', 'Padded Seats', 'Standing Only'
    ];

    const handleYesClick = () => {
        setShowCheckboxes(true);
        if (singleListingDetails?.restrictionsAndFeatures) {
            const features = singleListingDetails.restrictionsAndFeatures.split('|');
            setSelectedCheckboxes(features); // Restore selected checkboxes
            setActiveButton('yes');

        }
    };

    const handleNoClick = () => {
        setShowCheckboxes(false);
        setSelectedCheckboxes([]); // Clear selected checkboxes when "NO" is clicked
        setActiveButton('no');

    };

    const handleCheckboxChange = (label) => {
        setSelectedCheckboxes((prevSelected) =>
            prevSelected.includes(label)
                ? prevSelected.filter((item) => item !== label)
                : [...prevSelected, label]
        );
    };
    const numberOfElements = 30;

    // Initialize an array to store the elements
    const elements = [];

    // Use a for loop to generate the elements
    for (let i = 1; i <= numberOfElements; i++) {
        elements.push(i);
    }

    useEffect(() => {
        setSeats(singleListingDetails.Tickets)
        setValue('numberOfTickets', singleListingDetails.numberOfTickets)
        getAllSections()
    }, [])

    useEffect(() => {
        if (seats.length === 1) {
            setValue('sellingOption', 'ANY')
        }
    }, [seats.length])

    useEffect(() => {
        if (singleListingDetails?.restrictionsAndFeatures) {
            const features = singleListingDetails.restrictionsAndFeatures.split('|');
            setCheckboxLabels(allLabels); // Set all possible labels
            setSelectedCheckboxes(features); // Set selected checkboxes from the API data
            setHasRestrictions(features.length > 0);
            setShowCheckboxes(features.length > 0);
            setActiveButton('yes');

        } else {
            setCheckboxLabels(allLabels); // Set all possible labels if no restrictions in details
            setSelectedCheckboxes([]); // Clear selected checkboxes if no restrictions in details
            setHasRestrictions(false);
            setShowCheckboxes(false);
        }
    }, [singleListingDetails]);

    useEffect(() => {
        if (singleListingDetails?.sectionId) {
            getAllSUbSections(singleListingDetails?.sectionId)
        }
    }, [singleListingDetails?.sectionId])

    const switchTab = (tabNo) => {
        setActiveTab(tabNo)
    };

    const onSubmit = async (data) => {
        const allSelectedValues = Array.from(new Set([...selectedCheckboxes]));
        // Convert to pipe-separated string
        const updatedRestrictions = allSelectedValues.join('|');

        dispatch(clearAll({}))
        let same_rows;
        let avoid_leaving_single;

        if (data.sellingOption == 'FULL') {
            same_rows = seats.every(item => item.row == seats?.[0]?.row);
        }

        if (data.sellingOption == 'AVOID_LEAVING_SINGLE') {
            const countMap = {}
            let arr = seats.map((option) => {
                return option.row
            })
            arr.forEach(item => {
                countMap[item] = (countMap[item] || 0) + 1;
            });
            avoid_leaving_single = Object.values(countMap).every(count => count !== 1);
        }

        if (data.sellingOption == 'FULL' && same_rows == false) {
            setErrorType('full')
            setShowError(true)
        }
        else if (data.sellingOption == 'AVOID_LEAVING_SINGLE' && avoid_leaving_single == false) {
            setErrorType('avoid_leaving_single')
            setShowError(true)
        } else {
            setErrorType('')
            setShowError(false)
            let ticketIdsToBeRemoved = Array.from(removedSeats, (opt) => opt.id)
            data = {
                ...data,
                pricePerTicket: Number(data?.pricePerTicket),
                reservedPricePerTicket: Number(data?.reservedPricePerTicket),
                subSectionId: data.subSectionId || data.subSectionId != '' ? data.subSectionId : undefined,
                // sellTogether: data?.sellTogether == '1' ? true : false
                ticketIdsToBeRemoved,
                restrictionsAndFeatures: updatedRestrictions
            }
            console.log(data)
            const res = await post_put_patch_request(
                "patch",
                token,
                `${app_api.update_listing(singleListingDetails?.id)}`,
                data,
                dispatch,
                setCustomerLoading,
                "",
                setCustomerErrorMessage
            )
            if (res !== undefined && res.status < 400) {
                getList()
                dispatch(onUpdate({ message: res?.data?.message, type: 'listing' }))
                if (data.ticketsType === 'ELECTRONIC') {
                    dispatch(showTicketModalFunc({ modal: true, type: 'information' }))
                    dispatch(showListingModalFunc({ modal: false, type: null }))
                } else {
                    dispatch(setListingValues({ propertyKey: 'newData', value: true }))
                }
            }
        }
    }

    const getAllSections = async () => {
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            "",
            `${app_api.get_sections(singleListingDetails?.event?.venueId)}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if (res != undefined && res.status < 400) {
            setSections(res.data.data)
        }
    }

    const getAllSUbSections = async (selectedSection) => {
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            "",
            `${app_api.get_subsections(selectedSection)}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if (res != undefined && res.status < 400) {
            setSubSections(res.data.data)
        }
    }

    const closeModal = () => {
        dispatch(showListingModalFunc({ modal: false, type: null }))
        getList()
    }

    return (
        <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit Listing'}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="modal-body modal-editlistng">
                    <div className="px-4 px-md-7">
                        <div className="row gy-3">
                            <div className="form-label createdOn-text">Listed On<span className='evnt-text-pl'>:</span>{format(new Date(singleListingDetails?.createdAt), 'EEE dd MMM yyyy').toUpperCase()} </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="pricePerTicket" className="form-label">Price Per Ticket ({strText.currency_symbol})</label>
                                <input defaultValue={singleListingDetails?.pricePerTicketWithoutCommission} type="text" className="form-control" id="pricePerTicket" placeholder=""
                                    {...register("pricePerTicket", {
                                        required: {
                                            value: true,
                                            message: '*this field is required'
                                        }
                                    })
                                    }
                                />
                                {
                                    errors?.pricePerTicket?.message &&
                                    <div className="text-danger p mt-1">
                                        {errors?.pricePerTicket?.message}
                                    </div>
                                }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="reservedPricePerTicket" className="form-label">Reserved Price Per Ticket ({strText.currency_symbol})</label>
                                <input defaultValue={singleListingDetails?.reservedPricePerTicket - singleListingDetails?.commissionAmount} type="text" className="form-control" id="reservedPricePerTicket" placeholder=""
                                    {...register("reservedPricePerTicket", {
                                        required: {
                                            value: true,
                                            message: '*this field is required'
                                        },
                                        validate: value => Number(value) <= Number(getValues('pricePerTicket')) || "Amount greater than price per ticket",
                                    })
                                    }
                                />
                                {
                                    errors?.reservedPricePerTicket?.message &&
                                    <div className="text-danger p mt-1">
                                        {errors?.reservedPricePerTicket?.message}
                                    </div>
                                }
                            </div>




                            <div className='col-md-6'>
                                <label className='text-capitalize form-label' >How would you like to sell?</label>

                                <div className="my-1">
                                    <div className="form-check">
                                        <input
                                            id="sellingOption"
                                            name="paymentMethod"
                                            type="radio"
                                            defaultChecked={singleListingDetails?.sellingOption == 'ANY' ? true : false}
                                            value={'ANY'}
                                            className="form-check-input form-label"
                                            {...register("sellingOption", {
                                                required: {
                                                    value: true,
                                                    message: '*this field is required'
                                                }
                                            })
                                            }
                                        />
                                        <label className="form-check-label form-label" htmlFor="sellingOption">Any</label>
                                    </div>
                                    {seats.length > 1 &&
                                        <>
                                            <div className="form-check">
                                                <input
                                                    id="sellingOption"
                                                    name="paymentMethod"
                                                    type="radio"
                                                    defaultChecked={singleListingDetails?.sellingOption == 'FULL' ? true : false}
                                                    value={'FULL'}
                                                    className="form-check-input form-label"
                                                    {...register("sellingOption", {
                                                        required: {
                                                            value: true,
                                                            message: '*this field is required'
                                                        }
                                                    })
                                                    }
                                                />
                                                <label className="form-check-label form-label" htmlFor="sellingOption">Full</label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    id="sellingOption"
                                                    name="paymentMethod"
                                                    type="radio"
                                                    defaultChecked={singleListingDetails?.sellingOption == 'AVOID_LEAVING_SINGLE' ? true : false}
                                                    value={'AVOID_LEAVING_SINGLE'}
                                                    className="form-check-input form-label"
                                                    {...register("sellingOption", {
                                                        required: {
                                                            value: true,
                                                            message: '*this field is required'
                                                        }
                                                    })
                                                    }
                                                />
                                                <label className="form-check-label form-label" htmlFor="sellingOption">Avoid leaving single</label>
                                            </div>
                                        </>
                                    }
                                    {
                                        errors?.sellingOption?.message &&
                                        <div className="text-danger p mt-1">
                                            {errors?.sellingOption?.message}
                                        </div>
                                    }
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <label className='text-capitalize form-label'>What type of ticket are you listing?</label>

                                <div className="my-1">
                                    <div className="form-check">
                                        <input
                                            id="ticketsType"
                                            name="paymentMethod"
                                            type="radio"
                                            defaultChecked={singleListingDetails?.ticketsType == 'PHYSICAL' ? true : false}
                                            className="form-check-input form-label"
                                            value='PHYSICAL'
                                            {...register("ticketsType", {
                                                required: {
                                                    value: true,
                                                    message: '*this field is required'
                                                }
                                            })
                                            }
                                        />
                                        <label className="form-check-label form-label" htmlFor="ticketsType">Physical</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            id="ticketsType2"
                                            name="paymentMethod"
                                            type="radio"
                                            defaultChecked={singleListingDetails?.ticketsType == 'ELECTRONIC' ? true : false}
                                            className="form-check-input form-label"
                                            value='ELECTRONIC'
                                            {...register("ticketsType", {
                                                required: {
                                                    value: true,
                                                    message: '*this field is required'
                                                }
                                            })
                                            }
                                        />
                                        <label className="form-check-label form-label" htmlFor="ticketsType2">Electronic</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            id="ticketsType3"
                                            name="paymentMethod"
                                            type="radio"
                                            defaultChecked={singleListingDetails?.ticketsType == 'MOBILE' ? true : false}
                                            className="form-check-input form-label"
                                            value='MOBILE'
                                            {...register("ticketsType", {
                                                required: {
                                                    value: true,
                                                    message: '*this field is required'
                                                }
                                            })
                                            }
                                        />
                                        <label className="form-check-label form-label" htmlFor="ticketsType3">Mobile</label>
                                    </div>
                                    {
                                        errors?.ticketsType?.message &&
                                        <div className="text-danger p mt-1">
                                            {errors?.ticketsType?.message}
                                        </div>
                                    }
                                    {/* <div className="form-check">
                <input id="paypal" name="paymentMethod" type="radio" className="form-check-input" />
                <label className="form-check-label" htmlFor="paypal">PayPal</label>
                </div> */}
                                </div>
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <>
                                    <label htmlFor="sectionId" className="form-label">Section</label>
                                    {sectionsData.length > 0 &&
                                        <select className="form-select " id="sectionId"
                                            defaultValue={singleListingDetails?.sectionId}
                                            {...register("sectionId", {
                                                required: {
                                                    value: true,
                                                    message: '*this field is required'
                                                }
                                            })
                                            }
                                            onChange={(e) => {
                                                setValue("subSectionId", null);
                                                getAllSUbSections(e.target.value)
                                            }}
                                        >
                                            <option value="">Select Section</option>
                                            {sectionsData.map((section) => {
                                                return <option key={section?.id} value={section?.id}>{section?.name}</option>
                                            })}
                                        </select>
                                    }
                                    {
                                        errors?.sectionId?.message &&
                                        <div className="text-danger p mt-1">
                                            {errors?.sectionId?.message}
                                        </div>
                                    }
                                </>
                            </div>
                            <div className="form-group col-md-6 mb-4">
                                <>
                                    <label htmlFor="subSectionId" className="form-label">Block</label>
                                    {subSections.length > 0 &&
                                        <select className="form-select" id="subSectionId"
                                            defaultValue={singleListingDetails?.subSectionId}
                                            {...register("subSectionId")}
                                        >
                                            <option value="">Select Block</option>
                                            {subSections.map((subSection) => {
                                                return <option key={subSection?.id} value={subSection?.id}>{subSection?.name}</option>
                                            })}
                                        </select>}
                                    {
                                        errors?.subSectionId?.message &&
                                        <div className="text-danger p mt-1">
                                            {errors?.subSectionId?.message}
                                        </div>
                                    }
                                </>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="row" className="form-label">Row (optional)</label>
                                <input defaultValue={singleListingDetails?.row} type="text" className="form-control" id="row" placeholder=""
                                    {...register("row", {
                                        required: {
                                            value: false,
                                        }
                                    })
                                    }
                                />

                            </div>
                            <div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="fw-bold me-3 mb-0" style={{ color: "#000", fontSize: "16px" }}>Are there any restrictions/features on your tickets ?
                                    </h6>
                                    <div className="d-flex">
                                        <button
                                            className={`btn btn-lg me-2 btn-no-ticket ${activeButton == 'yes' ? 'btn-active' : 'btn-default'}`}
                                            type="button"
                                            onClick={handleYesClick}
                                        >YES
                                        </button>
                                        <button
                                            className={`btn btn-lg btn-no-ticket ${activeButton == 'no' ? 'btn-active' : 'btn-default'}`}
                                            type="button"
                                            onClick={handleNoClick}
                                        >
                                            NO
                                        </button>
                                    </div>
                                </div>
                                {showCheckboxes && (
                                    <div className="mt-3 p-3 border tickets-listsec">
                                        <div className="grid-container">
                                            {checkboxLabels.map((label, index) => (
                                                <div className="form-check" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`checkbox-${index}`}
                                                        onChange={() => handleCheckboxChange(label)}
                                                        checked={selectedCheckboxes.includes(label)}
                                                    />
                                                    <label className="form-check-label mb-0" htmlFor={`checkbox-${index}`}>
                                                        {label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item" onClick={() => { switchTab(1) }}>
                                    <a className={`nav-link ${activeTab == 1 ? 'active' : ''}`} id="pills-current-tab" aria-current="page" href="#nav-tabs-current">Tickets to be kept</a>
                                </li>
                                <li className="nav-item" onClick={() => { switchTab(2) }}>
                                    <a className={`nav-link ${activeTab == 2 ? 'active' : ''}`} id="nav-removed-tab" href="#nav-tab-removed">Removed tickets</a>
                                </li>
                            </ul>
                            <div className='my-3'>
                                {(showError && errorType == 'quantity') && <span className="text-danger p mt-1">
                                    You haave to have at least 1 ticket in the listing
                                </span>}
                                {(showError && errorType == 'full') && <span className="text-danger p mt-1">
                                    For full selling option, the rows need to be the same
                                </span>}
                                {(showError && errorType == 'avoid_leaving_single') && <span className="text-danger p mt-1">
                                    For avoid leaving single selling option, there should not be any unique rows
                                </span>}
                            </div>
                            <div className="tab-content mt-5 mt-md-0" id="nav-tabContent">
                                <div className={`tab-pane fade ${activeTab == 1 ? 'show active' : ''}`} id="nav-tabs-current" role="tabpanel" aria-labelledby="pills-current-tab">
                                    {seats.map((seat, ind) => {
                                        return <div key={seat.id} className='border px-1 pb-1 mb-2 rounded'>
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm"
                                                    onClick={() => {
                                                       setSeats((prevValue) => {
                                                            let x = prevValue.filter((option) => option.id != seat.id)
                                                            return x
                                                        })
                                                        setRemovedSeats((prevValue) => ([seat, ...prevValue]))
                                                        setValue('numberOfTickets', ticketNoValue - 1)
                                                        setErrorType('')
                                                        setShowError(false)
                                                    
                                                    }}
                                                >
                                                    <i style={{ fontSize: '1rem' }} className="mdi mdi-close"></i>
                                                </button>
                                            </div>
                                            <div className='row'>
                                                <div className="form-group col-md-8">
                                                    <label htmlFor="cc-name" className="form-label">Row</label>
                                                    <input disabled value={seat.row} type="text" className="form-control" id="cc-name" placeholder="" />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="cc-name" className="form-label">Seat No.</label>
                                                    <input disabled value={seat.seat} type="text" className="form-control" id="cc-name" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>

                                <div className={`tab-pane fade ${activeTab == 2 ? 'show active' : ''}`} id="nav-tab-removed" role="tabpanel" aria-labelledby="nav-removed-tab">

                                    {removedSeats.map((seat, ind) => {
                                        return <div key={seat.id} className='border px-1 pb-1 mb-2 rounded'>
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm"
                                                    onClick={() => {
                                                        setRemovedSeats((prevValue) => {
                                                            let x = prevValue.filter((option) => option.id != seat.id)
                                                            return x
                                                        })
                                                        setSeats((prevValue) => ([seat, ...prevValue]))
                                                        setValue('numberOfTickets', ticketNoValue - 1)
                                                        setErrorType('')
                                                        setShowError(false)
                                                    }}
                                                >
                                                    <i style={{ fontSize: '1rem' }} className="mdi mdi-plus"></i>
                                                </button>
                                            </div>
                                            <div className='row'>
                                                <div className="form-group col-md-8">
                                                    <label htmlFor="cc-name" className="form-label">Row</label>
                                                    <input disabled value={seat.row} type="text" className="form-control" id="cc-name" placeholder="" />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="cc-name" className="form-label">Seat No.</label>
                                                    <input disabled value={seat.seat} type="text" className="form-control" id="cc-name" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div> */}

                        <div className="modal-footer border-top-0 d-flex align-items-center">
                            <div>
                                {customerLoading ?
                                    <Spinner isForm={true} /> :
                                    <button type="submit" className="btn btn-primary me-3">Submit Form</button>
                                }
                            </div>
                            <button disabled={customerLoading ? true : false} onClick={closeModal} type="button" className="btn btn-info">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>

        </ModalLayout>
    )
}
